import React from 'react';
import '../styles/main.css'

const Preston = (props) => {
    
    return (
    <svg id='logo' className='centered' width="360" height="165" viewBox="0 0 537 143" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.192 37.632C18.784 33.12 22.624 29.376 27.712 26.4C32.896 23.328 38.896 21.792 45.712 21.792C52.72 21.792 59.056 23.472 64.72 26.832C70.48 30.192 74.992 34.944 78.256 41.088C81.52 47.136 83.152 54.192 83.152 62.256C83.152 70.224 81.52 77.328 78.256 83.568C74.992 89.808 70.48 94.656 64.72 98.112C59.056 101.568 52.72 103.296 45.712 103.296C38.992 103.296 33.04 101.808 27.856 98.832C22.768 95.76 18.88 91.968 16.192 87.456V139.44H3.08801V23.088H16.192V37.632ZM69.76 62.256C69.76 56.304 68.56 51.12 66.16 46.704C63.76 42.288 60.496 38.928 56.368 36.624C52.336 34.32 47.872 33.168 42.976 33.168C38.176 33.168 33.712 34.368 29.584 36.768C25.552 39.072 22.288 42.48 19.792 46.992C17.392 51.408 16.192 56.544 16.192 62.4C16.192 68.352 17.392 73.584 19.792 78.096C22.288 82.512 25.552 85.92 29.584 88.32C33.712 90.624 38.176 91.776 42.976 91.776C47.872 91.776 52.336 90.624 56.368 88.32C60.496 85.92 63.76 82.512 66.16 78.096C68.56 73.584 69.76 68.304 69.76 62.256Z" stroke="black" stroke-width="6" mask="url(#path-1-outside-1)" />
        <path d="M113.505 35.904C115.809 31.392 119.073 27.888 123.297 25.392C127.617 22.896 132.849 21.648 138.993 21.648V35.184H135.537C120.849 35.184 113.505 43.152 113.505 59.088V102H100.401V23.088H113.505V35.904Z" stroke="black" stroke-width="6" mask="url(#path-1-outside-1)" />
        <path d="M226.119 59.52C226.119 62.016 225.975 64.656 225.687 67.44H162.615C163.095 75.216 165.735 81.312 170.535 85.728C175.431 90.048 181.335 92.208 188.247 92.208C193.911 92.208 198.615 90.912 202.359 88.32C206.199 85.632 208.887 82.08 210.423 77.664H224.535C222.423 85.248 218.199 91.44 211.863 96.24C205.527 100.944 197.655 103.296 188.247 103.296C180.759 103.296 174.039 101.616 168.087 98.256C162.231 94.896 157.623 90.144 154.263 84C150.903 77.76 149.223 70.56 149.223 62.4C149.223 54.24 150.855 47.088 154.119 40.944C157.383 34.8 161.943 30.096 167.799 26.832C173.751 23.472 180.567 21.792 188.247 21.792C195.735 21.792 202.359 23.424 208.119 26.688C213.879 29.952 218.295 34.464 221.367 40.224C224.535 45.888 226.119 52.32 226.119 59.52ZM212.583 56.784C212.583 51.792 211.479 47.52 209.271 43.968C207.063 40.32 204.039 37.584 200.199 35.76C196.455 33.84 192.279 32.88 187.671 32.88C181.047 32.88 175.383 34.992 170.679 39.216C166.071 43.44 163.431 49.296 162.759 56.784H212.583Z" stroke="black" stroke-width="6" mask="url(#path-1-outside-1)" />
        <path d="M271.352 103.296C265.304 103.296 259.88 102.288 255.08 100.272C250.28 98.16 246.488 95.28 243.704 91.632C240.92 87.888 239.384 83.616 239.096 78.816H252.632C253.016 82.752 254.84 85.968 258.104 88.464C261.464 90.96 265.832 92.208 271.208 92.208C276.2 92.208 280.136 91.104 283.016 88.896C285.896 86.688 287.336 83.904 287.336 80.544C287.336 77.088 285.8 74.544 282.728 72.912C279.656 71.184 274.904 69.504 268.472 67.872C262.616 66.336 257.816 64.8 254.072 63.264C250.424 61.632 247.256 59.28 244.568 56.208C241.976 53.04 240.68 48.912 240.68 43.824C240.68 39.792 241.88 36.096 244.28 32.736C246.68 29.376 250.088 26.736 254.504 24.816C258.92 22.8 263.96 21.792 269.624 21.792C278.36 21.792 285.416 24 290.792 28.416C296.168 32.832 299.048 38.88 299.432 46.56H286.328C286.04 42.432 284.36 39.12 281.288 36.624C278.312 34.128 274.28 32.88 269.192 32.88C264.488 32.88 260.744 33.888 257.96 35.904C255.176 37.92 253.784 40.56 253.784 43.824C253.784 46.416 254.6 48.576 256.232 50.304C257.96 51.936 260.072 53.28 262.568 54.336C265.16 55.296 268.712 56.4 273.224 57.648C278.888 59.184 283.496 60.72 287.048 62.256C290.6 63.696 293.624 65.904 296.12 68.88C298.712 71.856 300.056 75.744 300.152 80.544C300.152 84.864 298.952 88.752 296.552 92.208C294.152 95.664 290.744 98.4 286.328 100.416C282.008 102.336 277.016 103.296 271.352 103.296Z" stroke="black" stroke-width="6" mask="url(#path-1-outside-1)" />
        <path d="M334.634 33.888V80.4C334.634 84.24 335.45 86.976 337.082 88.608C338.714 90.144 341.546 90.912 345.578 90.912H355.227V102H343.418C336.122 102 330.651 100.32 327.003 96.96C323.355 93.6 321.53 88.08 321.53 80.4V33.888H311.306V23.088H321.53V3.216H334.634V23.088H355.227V33.888H334.634Z" stroke="black" stroke-width="6" mask="url(#path-1-outside-1)" />
        <path d="M405.52 103.296C398.128 103.296 391.408 101.616 385.36 98.256C379.408 94.896 374.704 90.144 371.248 84C367.888 77.76 366.208 70.56 366.208 62.4C366.208 54.336 367.936 47.232 371.392 41.088C374.944 34.848 379.744 30.096 385.792 26.832C391.84 23.472 398.608 21.792 406.096 21.792C413.584 21.792 420.352 23.472 426.4 26.832C432.448 30.096 437.2 34.8 440.656 40.944C444.208 47.088 445.984 54.24 445.984 62.4C445.984 70.56 444.16 77.76 440.512 84C436.96 90.144 432.112 94.896 425.968 98.256C419.824 101.616 413.008 103.296 405.52 103.296ZM405.52 91.776C410.224 91.776 414.64 90.672 418.768 88.464C422.896 86.256 426.208 82.944 428.704 78.528C431.296 74.112 432.592 68.736 432.592 62.4C432.592 56.064 431.344 50.688 428.848 46.272C426.352 41.856 423.088 38.592 419.056 36.48C415.024 34.272 410.656 33.168 405.952 33.168C401.152 33.168 396.736 34.272 392.704 36.48C388.768 38.592 385.6 41.856 383.2 46.272C380.8 50.688 379.6 56.064 379.6 62.4C379.6 68.832 380.752 74.256 383.056 78.672C385.456 83.088 388.624 86.4 392.56 88.608C396.496 90.72 400.816 91.776 405.52 91.776Z" stroke="black" stroke-width="6" mask="url(#path-1-outside-1)" />
        <path d="M501.661 21.648C511.261 21.648 519.037 24.576 524.989 30.432C530.941 36.192 533.917 44.544 533.917 55.488V102H520.957V57.36C520.957 49.488 518.989 43.488 515.053 39.36C511.117 35.136 505.741 33.024 498.925 33.024C492.013 33.024 486.493 35.184 482.365 39.504C478.333 43.824 476.317 50.112 476.317 58.368V102H463.213V23.088H476.317V34.32C478.909 30.288 482.413 27.168 486.829 24.96C491.341 22.752 496.285 21.648 501.661 21.648Z" stroke="black" stroke-width="6" mask="url(#path-1-outside-1)" />
      </svg>
    )
}


export default Preston;